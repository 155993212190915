require('normalize.css/normalize.css');
require('./styles/index.scss');

import runtime from 'serviceworker-webpack-plugin/lib/runtime';

document.addEventListener("DOMContentLoaded", () => {
  // Check if we can use service workers
  if ('serviceWorker' in navigator) {
    const registration = runtime.register()
      .then(res => {
      })
      .catch(e => console.log(e));
  }
});
